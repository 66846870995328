@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-xl: 20px;

  /* Colors */
  --color-gray-100: #222;
  --color-white: #fff;

  /* Border radiuses */
  --br-8xs: 5px;
}
